import React from "react"
import { Link } from "gatsby"
import SocialBar from "./SocialBar"

import { footer as footerNav } from "../../config/navigation"

export default function Footer() {
  return (
    <footer className="bg-pattern">
      <SocialBar />
      <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-screen-xl mx-auto overflow-hidden">
        <nav className="-mx-5 -my-2 hidden md:flex flex-wrap justify-center">
          {footerNav.map(({ href, name }) => (
            <div key={`footer_link_${name}`} className="px-5 py-2">
              <Link
                key={`nav-footer-${href}`}
                to={href}
                className="text-base leading-6 text-gray-500 hover:text-gray-900"
              >
                {name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="md:mt-8">
          <img
            className="mb-8 mx-auto h-8 w-auto"
            src="/images/logo.png"
            alt="Ascension Sports Massage"
            height="185"
            width="102"
          />
          <p className="text-center text-base leading-6 text-gray-400">
            © {new Date().getFullYear()} Ascension Sports Massage. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
