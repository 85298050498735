export const header = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Key Principles",
    href: "/key-principles",
  },
  {
    name: "Treatments",
    href: "/treatments",
  },
  {
    name: "Prices",
    href: "/prices",
  },
  {
    name: "About",
    href: "/about",
  },
  {
    name: "Contact",
    href: "/contact",
  },
]

export const mobile = [...header]

export const footer = [...header]
