import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import { Transition } from "@tailwindui/react"
import Container from "./Elements/Container"
import SocialBar from "./SocialBar"

import { header, mobile } from "../../config/navigation"

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className="bg-pattern">
      <SocialBar />

      <Container className="mx-auto px-4">
        <div className="relative">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div>
              <Link to="/" className="flex">
                <img
                  className="h-8 w-auto sm:h-10"
                  src="/images/logo.png"
                  title="Ascension Sports Massage"
                  alt="Ascension Sports Massage"
                  height="185"
                  width="102"
                />
              </Link>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                aria-label="Open mobile navigation menu"
                onClick={() => setMenuOpen(true)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-end md:space-x-12">
              <nav className="flex space-x-10">
                {header.map(({ href, name }) => (
                  <Link
                    key={`nav-${href}`}
                    to={href}
                    className="text-base font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
                    activeClassName="text-gray-900 border-b-2 border-b-blue"
                  >
                    {name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>

          <Transition
            show={menuOpen}
            className="absolute top-0 inset-x-0 p-2 origin-top-right md:hidden z-40"
            enter="transition duration-200 ease-out transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition duration-100 ease-in transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="rounded-lg shadow-lg">
              <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5 space-y-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="/images/logo.png"
                        alt="Ascension Sports Massage"
                        height="185"
                        width="102"
                      />
                    </div>
                    <div className="-mr-2">
                      <button
                        type="button"
                        aria-label="Close mobile navigation menu"
                        onClick={() => setMenuOpen(false)}
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      >
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="cta">
                    <nav className="grid gap-6">
                      {mobile.map(({ href, name }) => (
                        <Link
                          key={`nav-mobile-${href}`}
                          to={href}
                          onClick={() => setMenuOpen(false)}
                          className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <div className="text-base leading-6 font-medium text-gray-900">
                            {name}
                          </div>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </Container>
    </header>
  )
}
