import React from "react"
import cn from "classnames"
import { Container } from "./Elements"
import Phone from "../assets/svgs/Phone"
import Mail from "../assets/svgs/Mail"
import FbCircle from "../assets/svgs/FbCircle"
import InstagramCircle from "../assets/svgs/InstagramCircle"

export default function SocialBar({ className }) {
  return (
    <div
      className={cn(
        "not-sr-only bg-b-blue hidden md:block bg-gradiant-blue",
        className
      )}
    >
      <Container className="h-14 flex justify-between items-center">
        <dl className="flex text-white">
          <dt className="sr-only">Phone number</dt>
          <dd className="mr-4 flex items-center">
            <Phone className="flex-shrink-0 h-6 w-6" />
            <a className="ml-1" href="tel:07568359825">
              07568359825
            </a>
          </dd>
          <dt className="sr-only">Email</dt>
          <dd className="flex items-center">
            <Mail className="flex-shrink-0 h-6 w-6" />
            <a className="ml-1" href="mailto:ascensionsportsmassage@gmail.com">
              ascensionsportsmassage@gmail.com
            </a>
          </dd>
        </dl>
        <div className="flex space-x-4">
          <a
            href="https://www.facebook.com/Ascension-Sports-Massage-104036905072281"
            className="text-white hover:text-gray-200"
            rel="noopener noreferrer"
            target="__blank"
          >
            <span className="sr-only">Facebook</span>
            <FbCircle className="h-6 w-6" />
          </a>
          <a
            href="https://www.instagram.com/Ascensionsportsmassage/"
            className="text-white hover:text-gray-200"
            rel="noopener noreferrer"
            target="__blank"
          >
            <span className="sr-only">Instagram</span>
            <InstagramCircle className="h-6 w-6" />
          </a>
        </div>
      </Container>
    </div>
  )
}
